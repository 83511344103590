import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/portal/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["RootStoreProvider"] */ "/vercel/path0/portal/src/stores/rootStoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/custom-ant.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/news-layout.scss");
